import React, { useState, ChangeEvent, useRef } from "react";
import axios from "axios";
import {
	Container,
	Header,
	Form,
	Button,
	Select,
	Segment,
	DropdownProps,
	Message
} from "semantic-ui-react";

const options = [
	{ key: "p", text: "Pass", value: "pass" },
	{ key: "g", text: "Goal", value: "goal" },
	{ key: "o", text: "Other", value: "other" }
];

const App: React.FC = () => {
	const [name, setName] = useState("");
	const [type, setType] = useState("");
	const [responseSingle, setResponseSingle] = useState("");
	const [responseFile, setResponseFile] = useState("");
	const [fileError, setFileError] = useState("");
	const [file, setFile] = useState();

	let fileInput = useRef<HTMLInputElement>(null);

	const handleTriggerSubmit = () => {
		axios
			.post(`${process.env.REACT_APP_API_PATH}/api/single`, {
				name,
				type
			})
			.then(res => {
				setResponseSingle("Success!");
				setTimeout(() => setResponseSingle(""), 1000);
			})
			.catch(err => console.log(err));
	};

	const handleFileSubmit = () => {
		const formData = new FormData();
		formData.append("file", file);
		setFileError("");

		axios
			.post(`${process.env.REACT_APP_API_PATH}/api/file`, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then(res => {
				if (fileInput && fileInput.current)
					fileInput.current.value = "";
				setFile(null);
				setResponseFile("Success!");
				setTimeout(() => setResponseFile(""), 1000);
			})
			.catch(err => setFileError(err.response.data.error));
	};

	return (
		<div
			style={{
				minHeight: "100vh",
				display: "flex",
				alignItems: "center"
			}}
		>
			<Container text>
				<Segment attached>
					<Header as="h2">Pass and Move feed provider</Header>
					<Form onSubmit={() => handleTriggerSubmit()}>
						<Form.Field>
							<label>Player name</label>
							<input
								placeholder="Player name"
								onChange={({
									currentTarget: { value }
								}: ChangeEvent<HTMLInputElement>) =>
									setName(value)
								}
								value={name}
							/>
						</Form.Field>
						<Form.Field>
							<label>Event type</label>
							<Select
								options={options}
								onChange={(e, { value }: DropdownProps) =>
									typeof value === "string" && setType(value)
								}
								value={type}
							/>
						</Form.Field>
						<Button positive type="submit">
							Trigger event
						</Button>
					</Form>
				</Segment>
				<Message attached="bottom" positive={!!responseSingle}>
					{responseSingle}
				</Message>
				<Segment attached>
					<Form onSubmit={() => handleFileSubmit()}>
						<Form.Field>
							<input
								type="file"
								id="file"
								style={{ display: "hidden" }}
								onChange={({
									target
								}: ChangeEvent<HTMLInputElement>) =>
									setFile(
										target &&
											target.files &&
											target.files[0]
									)
								}
								ref={fileInput}
							/>
						</Form.Field>
						<Button negative type="submit">
							Run feed file
						</Button>
					</Form>
				</Segment>
				<Message
					attached="bottom"
					positive={!!responseFile}
					negative={!!fileError}
				>
					{responseFile}
					{fileError}
				</Message>
			</Container>
		</div>
	);
};

export default App;
